{
    const menu = document.querySelector(".menu");
    const menuBar = document.querySelector(".menu-list-toggle-bar");
    const menuList = document.querySelector(".menu-list-box");
    const menuClose = document.querySelector(".menu-list-close");

    menuBar.onclick = () => {
        menu.classList.toggle("is-show");
    }

    menuList.onclick = (e) => {
        e.stopPropagation();
    }

    menuClose.onclick = () => {
        menu.classList.toggle("is-show");
    }

    const menuLinks = document.querySelectorAll(".menu-list-sub-toggle");
    menuLinks.forEach(el => {
        el.onclick = () => {
            el.closest(".menu-list-el--sub").classList.toggle("is-show");
        }
    })
}

{
    const headerUser = document.querySelector(".p-header-user");
    const headerUserBtn = document.querySelector(".p-header-user-btn");
    const userBox = document.querySelector(".p-header-user-box");

    if (headerUser && headerUserBtn && userBox) {
        headerUser.onclick = e => {
            e.stopPropagation();
        }

        document.onclick = () => {
            userBox.classList.remove("is-show");
        }

        headerUserBtn.onclick = () => {
            userBox.classList.toggle("is-show");
        }
    }
}


{
    const slider = new Swiper("#mainEvents", {
        direction: 'horizontal',
        slidesPerView: 1,
        navigation: {
            nextEl: "#mainEventsNext",
            prevEl: "#mainEventsPrev",
        },
        loop: true,
        effect: 'fade',
        autoplay: {
            delay: 4000
        }
    })
}

{
    let observerOptions = {
        rootMargin: "0px 0px -40% 0px",
        threshold: 0
    }

    let observer = new IntersectionObserver(elements => {
        for (let el of elements) {
            if (el.isIntersecting) {
                el.target.classList.add("is-animating")
            }
        }
    }, observerOptions);

    const div = document.querySelectorAll(".main-section");
    for (let el of div) {
        observer.observe(el);
    }
}

{
    const swiper = new Swiper('#mainNewsList', {
        direction: 'horizontal',
        slidesPerView: 1,
        loop: false,
        navigation: {
            nextEl: "#mainNewsNext",
            prevEl: "#mainNewsPrev",
        }
    });
}